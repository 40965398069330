@import "styles/utils/mixins";

.wrapper {
  padding: 35px 0;
  background-color: #efeff3;
}

.headline {
  width: 50%;
  font-family: "TT Interfaces", sans-serif;
  margin-bottom: 86px;
}

.block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.content {
  width: 50%;
}

.description {
  h4 {
    margin-bottom: 50px;
  }
}

.social {
  display: flex;
  align-items: center;
  margin: -7px;

  .button {
    color: #dfe0e7;
    margin: 7px;
  }
}

.circle {
  position: relative;
  width: 300px;
  height: 300px;
  border: 1px solid rgb(206 16 80 / 20%);
  border-radius: 50%;
  transform: translate(-50%, -15%);
}

.link {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translate(-45px, -50%);
  left: 50%;
  white-space: nowrap;
}

.arrow {
  position: relative;
  display: flex;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: 0 38px 0 0;

  path:first-of-type {
    stroke: #ce1050;
  }

  path:last-of-type {
    fill: #ce1050;
  }
}

.icon {
  width: 24px;
  height: 24px;
  margin: auto;
}

@include small-screen {
  .wrapper {
    padding: 40px 0;
  }

  .headline {
    width: 100%;
    margin-bottom: 65px;
  }

  .social {
    margin-bottom: 40px;
  }

  .block {
    flex-direction: column;
  }

  .description {
    h4 {
      margin-bottom: 35px;
    }
  }

  .content {
    width: 100%;
  }

  .circle {
    width: 160px;
    height: 160px;
    border: 1px solid rgb(206 16 80 / 20%);
    transform: none;
  }

  .arrow {
    margin: 0 10px 0 0;
  }
}
